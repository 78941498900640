import { Skeleton } from "components/main/Skeleton";
import dynamic from "next/dynamic";

export const RichText = dynamic(
  () => import("./RichTextInternal").then((mod) => mod.RichTextInternal),
  {
    loading: () => <Skeleton height="550px" width="100%" />,
    ssr: false,
  }
);
