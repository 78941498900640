import { css } from "@@panda/css";
import ReactSkeleton, { SkeletonProps } from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

export function Skeleton(p: SkeletonProps) {
  return (
    <ReactSkeleton
      containerClassName={css({ width: "full" })}
      baseColor="#282828"
      highlightColor="#424242"
      data-testid="skeleton-row"
      {...p}
    />
  );
}
