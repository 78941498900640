import { DealStatus, OdinFeePaidBy } from "@internal/gql/generated/types";
import {
  DraftTermsEquityShareClass,
  InvestmentRound,
  PlatformFeeType,
  PricePlanName,
  ProxyVotingTarget,
  ValuationCapType,
} from "@internal/rest/generated/schemas";
import { exhaustiveCheck } from "@internal/utils/types";

export const DEFAULT_MIN_COMMITMENT = 1000;
export const DEFAULT_PRICE_PLAN = PricePlanName.syndicate_2024;

export function formatInvestmentRound(
  round: InvestmentRound | undefined
): string {
  if (!round) return "";

  switch (round) {
    case "bridge_to_series_a":
      return "Bridge to Series A";
    case "bridge_to_series_b":
      return "Bridge to Series B";
    case "bridge_to_series_c":
      return "Bridge to Series C";
    case "bridge_to_series_d":
      return "Bridge to Series D+";
    case "series_a":
      return "Series A";
    case "series_b":
      return "Series B";
    case "series_c":
      return "Series C";
    case "series_d":
      return "Series D+";
    case "pre_seed":
      return "Pre-seed";
    case "seed":
      return "Seed";
    case "secondary":
      return "Secondary";
    default:
      return exhaustiveCheck(round, { fallback: "" });
  }
}

export type FormattedOdinPlatformFeePayee =
  | "The Investee company"
  | "I will cover the costs"
  | "The investors (pro-rata)"
  | "The Seller";

export function formatOdinPlatformFeePayee(
  payee: PlatformFeeType
): FormattedOdinPlatformFeePayee | "" {
  if (!payee) return "";

  switch (payee) {
    case "investee_company":
      return "The Investee company";
    case "syndicate_leads":
      return "I will cover the costs";
    case "investors_pro_rata":
      return "The investors (pro-rata)";
    case "seller":
      return "The Seller";
    default:
      return exhaustiveCheck(payee, { fallback: "" });
  }
}

export function formatProxyVotingValue(value: ProxyVotingTarget | undefined) {
  if (!value) return "";
  return {
    [ProxyVotingTarget.chair_of_board]: "Chair of board",
    [ProxyVotingTarget.do_not_proxy]: "None",
    [ProxyVotingTarget.founders]: "Founders",
    [ProxyVotingTarget.syndicate_lead]: "Syndicate lead",
  }[value];
}

export function formatShareClassValue(
  value: DraftTermsEquityShareClass | undefined
) {
  if (!value) return "";
  return {
    [DraftTermsEquityShareClass.common]: "Common/Ordinary",
    [DraftTermsEquityShareClass.preferred]: "Preferred",
  }[value];
}

export function formatValuationCapType(type: ValuationCapType | undefined) {
  if (!type) return "";
  if (type === "none") return "None";
  if (type === "post") return "Post-money";
  return "Pre-money";
}

export const odinFeePaidByMap = {
  INVESTEE_COMPANY: "Investee company",
  INVESTORS_PRO_RATA: "Investors pro rata",
  SELLER: "Seller",
  SYNDICATE_LEADS: "Syndicate leads",
} as const satisfies Record<OdinFeePaidBy, string>;

export type ClosingStatus = Extract<
  DealStatus,
  | "CALCULATING_FEES"
  | "AWAITING_LEGALS"
  | "REVIEWING_LEGALS"
  | "WIRING_FUNDS"
  | "CLOSING"
>;

export function isDealClosing(status: DealStatus | undefined) {
  switch (status) {
    case "CALCULATING_FEES":
    case "AWAITING_LEGALS":
    case "REVIEWING_LEGALS":
    case "WIRING_FUNDS":
    case "CLOSING":
      return true;
    default:
      return false;
  }
}

export type DealType = "Founder" | "Primary" | "Secondary";

type GetDealTypeArgs = {
  __typename: "Primary" | "Secondary";
  lead?: { __typename?: "Founder" | "Syndicate" } | null;
};

export const getDealType = (args: GetDealTypeArgs): DealType => {
  if (args.lead?.__typename === "Founder") return "Founder";
  return args.__typename;
};

export type SimpleDealStatus = "LIVE" | "CLOSING" | "CLOSED";

type GetDealStatusReturn = {
  simple: SimpleDealStatus;
  raw: DealStatus;
};

export const getDealStatus = (status: DealStatus): GetDealStatusReturn => {
  const getSimpliedStatus = () => {
    if (isDealClosing(status)) return "CLOSING";
    if (status === "CLOSED") return "CLOSED";
    return "LIVE";
  };

  return { simple: getSimpliedStatus(), raw: status };
};
