import { Token } from "@@panda/tokens";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";

export const PricePlanColors: Record<PricePlanName, Token> = {
  [PricePlanName.founder_2024]: "colors.red.salmon",
  [PricePlanName.syndicate_2024]: "colors.red.salmon",
  [PricePlanName.syndicate_plus_2024]: "colors.purple.fauve",
  [PricePlanName.institutional_2024]: "colors.green.moss",

  [PricePlanName.founder_2020]: "colors.red.salmon",
  [PricePlanName.entry_2020]: "colors.green.moss",
  [PricePlanName.standard_2020]: "colors.purple.fauve",

  [PricePlanName.founder_2022]: "colors.red.salmon",
  [PricePlanName.standard_2022]: "colors.purple.fauve",
  [PricePlanName.branded_2022]: "colors.green.moss",

  [PricePlanName.founder_2023]: "colors.red.salmon",
  [PricePlanName.standard_2023]: "colors.purple.fauve",
  [PricePlanName.branded_2023]: "colors.green.moss",
  [PricePlanName.founder_2023_07]: "colors.red.salmon",
  [PricePlanName.founder_2023_09]: "colors.red.salmon",

  [PricePlanName.base_2023]: "colors.red.salmon",
  [PricePlanName.core_2023]: "colors.purple.fauve",
  [PricePlanName.core_2023_monthly]: "colors.purple.fauve",
  [PricePlanName.headliner_2023]: "colors.green.moss",
  [PricePlanName.headliner_2023_monthly]: "colors.green.moss",
};

export interface PricePlanValueSet {
  floor?: string;
  cappedAt: string;
  price: string;
  monthlyPrice?: string;
  oldPrice?: string;
  hint?: string;
  tbd?: boolean; // To be determined, the card shows a "Contact us" text
}

type Currencies = Omit<typeof BaseCurrency, "usdc">;

type PricePlanValuesProps = {
  [key in PricePlanName]: {
    description: string;
    plusPercentageFundsRaised: string | null; // null means a fixed price
    prices: {
      [K in keyof Currencies]: PricePlanValueSet;
    };
  };
};

export const PricePlanValues: PricePlanValuesProps = {
  [PricePlanName.institutional_2024]: {
    description:
      "Want to create a limited partnership, offshore structure or a multi-asset vehicle?",
    plusPercentageFundsRaised: "4",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "–",
        price: "–",
        tbd: true,
      },
      [BaseCurrency.usd]: {
        cappedAt: "–",
        price: "–",
        tbd: true,
      },
      [BaseCurrency.eur]: {
        cappedAt: "–",
        price: "–",
        tbd: true,
      },
    },
  },
  [PricePlanName.founder_2024]: {
    description:
      "'Odin Investments Limited' will be the entity that appears on the cap table.",
    plusPercentageFundsRaised: "0",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "4,000",
        price: "4,000",
        floor: "4,000",
      },
      [BaseCurrency.usd]: {
        cappedAt: "6,000",
        price: "6,000",
        floor: "6,000",
      },
      [BaseCurrency.eur]: {
        cappedAt: "5,000",
        price: "5,000",
        floor: "5,000",
      },
    },
  },
  [PricePlanName.syndicate_2024]: {
    description:
      "'Odin Investments Limited' will be the entity that appears on investee company cap tables.",
    plusPercentageFundsRaised: "4",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "10,000",
        price: "0",
        floor: "4,000",
      },
      [BaseCurrency.usd]: {
        cappedAt: "14,000",
        price: "0",
        floor: "6,000",
      },
      [BaseCurrency.eur]: {
        cappedAt: "13,000",
        price: "0",
        floor: "5,000",
      },
    },
  },
  [PricePlanName.syndicate_plus_2024]: {
    description:
      "We'll create a custom entity for you. Its name will appear on investee company cap tables.",
    plusPercentageFundsRaised: "3.5",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "9,000",
        price: "3,000",
        floor: "3,500",
      },
      [BaseCurrency.usd]: {
        cappedAt: "12,000",
        price: "5,000",
        floor: "5,500",
      },
      [BaseCurrency.eur]: {
        cappedAt: "11,000",
        price: "4,000",
        floor: "4,500",
      },
    },
  },
  [PricePlanName.branded_2023]: {
    description:
      "We’ll incorporate your own entity, which will be the name on the cap table.",
    plusPercentageFundsRaised: "1.95",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "8,500",
        price: "2,975",
      },
      [BaseCurrency.usd]: {
        cappedAt: "10,000",
        price: "3,500",
      },
      [BaseCurrency.eur]: {
        cappedAt: "9,500",
        price: "3,325",
      },
    },
  },
  [PricePlanName.standard_2023]: {
    description:
      "We’ll use an existing entity, Odin’s name will be on the cap table.",
    plusPercentageFundsRaised: "1.95",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "8,500",
        price: "1,700",
      },
      [BaseCurrency.usd]: {
        cappedAt: "10,000",
        price: "2,000",
      },
      [BaseCurrency.eur]: {
        cappedAt: "9,500",
        price: "1,900",
      },
    },
  },
  [PricePlanName.founder_2023]: {
    description:
      "'Odin Investments Limited' will be the entity that appears on the cap table.",
    plusPercentageFundsRaised: null,
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "2,125",
        price: "2,125",
      },
      [BaseCurrency.usd]: {
        cappedAt: "2,500",
        price: "2,500",
      },
      [BaseCurrency.eur]: {
        cappedAt: "2,375",
        price: "2,375",
      },
    },
  },
  [PricePlanName.branded_2022]: {
    description:
      "We’ll incorporate your own entity, which will be the name on the cap table.",
    plusPercentageFundsRaised: "1.6",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "7,000",
        price: "2,000",
      },
      [BaseCurrency.usd]: {
        cappedAt: "8,000",
        price: "3,000",
      },
      [BaseCurrency.eur]: {
        cappedAt: "7,500",
        price: "2,500",
      },
    },
  },
  // Founder
  [PricePlanName.standard_2022]: {
    description:
      "We’ll use an existing entity, Odin’s name will be on the cap table.",
    plusPercentageFundsRaised: "1.8",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "4,500",
        price: "1,100",
      },
      [BaseCurrency.usd]: {
        cappedAt: "5,100",
        price: "1,500",
      },
      [BaseCurrency.eur]: {
        cappedAt: "4,800",
        price: "1,400",
      },
    },
  },
  [PricePlanName.founder_2022]: {
    description:
      "'Odin Investments Limited' will be the entity that appears on the cap table.",
    plusPercentageFundsRaised: "1.9",
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "2,000",
        price: "1,000",
      },
      [BaseCurrency.usd]: {
        cappedAt: "2,500",
        price: "1,400",
      },
      [BaseCurrency.eur]: {
        cappedAt: "2,400",
        price: "1,200",
      },
    },
  },
  [PricePlanName.founder_2023_07]: {
    description:
      "'Odin Investments Limited' will be the entity that appears on the cap table.",
    plusPercentageFundsRaised: null,
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "2,500",
        price: "2,500",
      },
      [BaseCurrency.usd]: {
        cappedAt: "2,940",
        price: "2,940",
      },
      [BaseCurrency.eur]: {
        cappedAt: "2,800",
        price: "2,800",
      },
    },
  },

  [PricePlanName.founder_2023_09]: {
    description:
      "'Odin Investments Limited' will be the entity that appears on the cap table.",
    plusPercentageFundsRaised: null,
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "2,500",
        price: "2,500",
      },
      [BaseCurrency.usd]: {
        cappedAt: "3,450",
        price: "3,450",
      },
      [BaseCurrency.eur]: {
        cappedAt: "3,100",
        price: "3,100",
      },
    },
  },

  [PricePlanName.base_2023]: {
    description:
      "We'll use an existing entity, Odin's name will be on the cap table.",
    plusPercentageFundsRaised: "3.95",
    prices: {
      [BaseCurrency.gbp]: {
        floor: "1,800",
        cappedAt: "10,000",
        price: "0",
        monthlyPrice: "0",
      },
      [BaseCurrency.usd]: {
        floor: "2,500",
        cappedAt: "11,500",
        price: "0",
      },
      [BaseCurrency.eur]: {
        floor: "2,200",
        cappedAt: "11,000",
        price: "0",
      },
    },
  },
  [PricePlanName.core_2023]: {
    description:
      "We’ll incorporate your own entity, which will be the name on the cap table.",
    plusPercentageFundsRaised: "3.45",
    prices: {
      [BaseCurrency.gbp]: {
        floor: "1,550",
        cappedAt: "8,500",
        price: "2,500",
        monthlyPrice: "229",
      },
      [BaseCurrency.usd]: {
        floor: "2,150",
        cappedAt: "10,000",
        price: "2,941",
      },
      [BaseCurrency.eur]: {
        floor: "1,900",
        cappedAt: "9,500",
        price: "2,795",
      },
    },
  },
  [PricePlanName.core_2023_monthly]: {
    description: "TEMPORARY",
    plusPercentageFundsRaised: "TEMPORARY",
    prices: {
      [BaseCurrency.gbp]: {
        floor: "TEMPORARY",
        cappedAt: "TEMPORARY",
        price: "TEMPORARY",
        monthlyPrice: "TEMPORARY",
      },
      [BaseCurrency.usd]: {
        floor: "TEMPORARY",
        cappedAt: "TEMPORARY",
        price: "TEMPORARY",
      },
      [BaseCurrency.eur]: {
        floor: "TEMPORARY",
        cappedAt: "TEMPORARY",
        price: "TEMPORARY",
      },
    },
  },
  [PricePlanName.headliner_2023]: {
    description:
      "We’ll incorporate your own entity, which will be the name on the cap table.",
    plusPercentageFundsRaised: "2.95",
    prices: {
      [BaseCurrency.gbp]: {
        floor: "1,300",
        cappedAt: "7,000",
        price: "7,500",
        monthlyPrice: "688",
      },
      [BaseCurrency.usd]: {
        floor: "1,800",
        cappedAt: "8,500",
        price: "8,824",
      },
      [BaseCurrency.eur]: {
        floor: "1,600",
        cappedAt: "8,000",
        price: "8,382",
      },
    },
  },
  [PricePlanName.headliner_2023_monthly]: {
    description: "TEMPORARY",
    plusPercentageFundsRaised: "TEMPORARY",
    prices: {
      [BaseCurrency.gbp]: {
        floor: "TEMPORARY",
        cappedAt: "TEMPORARY",
        price: "TEMPORARY",
        monthlyPrice: "TEMPORARY",
      },
      [BaseCurrency.usd]: {
        floor: "TEMPORARY",
        cappedAt: "TEMPORARY",
        price: "TEMPORARY",
      },
      [BaseCurrency.eur]: {
        floor: "TEMPORARY",
        cappedAt: "TEMPORARY",
        price: "TEMPORARY",
      },
    },
  },
  // It's impossible we have any  deals on this price plan, but added for types not to break
  [PricePlanName.founder_2020]: {
    description:
      "Roll up your investors into a single entity to keep your cap table simple.",
    plusPercentageFundsRaised: null,
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "2,500",
        price: "2,500",
      },
      [BaseCurrency.usd]: {
        cappedAt: "2,940",
        price: "2,940",
      },
      [BaseCurrency.eur]: {
        cappedAt: "2,800",
        price: "2,800",
      },
    },
  },
  [PricePlanName.entry_2020]: {
    description:
      "Roll up your investors into a single entity to keep your cap table simple.",
    plusPercentageFundsRaised: null,
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "2,500",
        price: "2,500",
      },
      [BaseCurrency.usd]: {
        cappedAt: "2,940",
        price: "2,940",
      },
      [BaseCurrency.eur]: {
        cappedAt: "2,800",
        price: "2,800",
      },
    },
  },
  [PricePlanName.standard_2020]: {
    description:
      "Roll up your investors into a single entity to keep your cap table simple.",
    plusPercentageFundsRaised: null,
    prices: {
      [BaseCurrency.gbp]: {
        cappedAt: "2,500",
        price: "2,500",
      },
      [BaseCurrency.usd]: {
        cappedAt: "2,940",
        price: "2,940",
      },
      [BaseCurrency.eur]: {
        cappedAt: "2,800",
        price: "2,800",
      },
    },
  },
};

export function isLegacy2023PricePlan(pp: PricePlanName | undefined): boolean {
  switch (pp) {
    case PricePlanName.base_2023:
    case PricePlanName.core_2023:
    case PricePlanName.core_2023_monthly:
    case PricePlanName.headliner_2023:
    case PricePlanName.headliner_2023_monthly:
      return true;

    default:
      return false;
  }
}

export function getLabelFromPricePlan(plan: PricePlanName) {
  return (
    {
      [PricePlanName.founder_2024]: "Founder",
      [PricePlanName.syndicate_2024]: "Syndicate",
      [PricePlanName.syndicate_plus_2024]: "Syndicate Plus",
      [PricePlanName.institutional_2024]: "Institutional",

      [PricePlanName.founder_2020]: "Founder",
      [PricePlanName.entry_2020]: "Entry",
      [PricePlanName.standard_2020]: "Standard",

      [PricePlanName.founder_2022]: "Founder",
      [PricePlanName.standard_2022]: "Standard",
      [PricePlanName.branded_2022]: "Branded",

      [PricePlanName.founder_2023]: "Founder",
      [PricePlanName.standard_2023]: "Standard",
      [PricePlanName.branded_2023]: "Branded",
      [PricePlanName.founder_2023_07]: "Founder",
      [PricePlanName.founder_2023_09]: "Founder",

      [PricePlanName.base_2023]: "Base",
      [PricePlanName.core_2023]: "Core",
      [PricePlanName.core_2023_monthly]: "Core",
      [PricePlanName.headliner_2023]: "Headliner",
      [PricePlanName.headliner_2023_monthly]: "Headliner",
    }[plan] || "Plan"
  );
}
