import { Currency } from "@internal/gql/generated/types";

import { exhaustiveCheck } from "../types";

export function getCurrencySign(
  baseCurrency: Currency | Lowercase<Currency>
): string {
  if (!baseCurrency) return "";
  if (typeof baseCurrency !== "string") return "";

  const normalised = baseCurrency.toLowerCase() as Lowercase<
    typeof baseCurrency
  >;

  switch (normalised) {
    case "usd":
      return "$";
    case "gbp":
      return "£";
    case "eur":
      return "€";
    case "usdc":
      return "USDC";

    default:
      return exhaustiveCheck(normalised, { fallback: "" });
  }
}
export const getCurrencyDecimals = (currency: Currency): number => {
  switch (currency.toLowerCase()) {
    case "usd":
      return 2;
    case "gbp":
      return 2;
    case "eur":
      return 2;
    case "usdc":
      return 5;

    default:
      throw new Error(`no available currency symbol for ${currency}`);
  }
};

export function formatNumberWithCommas(num: number): string {
  return Number(num.toString().replace(/,/g, "")).toLocaleString("en");
}

export function formatCurrencyNoDP(num: number, baseCurrency: string): string {
  if (baseCurrency === "usdc") return `${num} USDC`;

  return Number(num).toLocaleString("en-EN", {
    style: "currency",
    currency: baseCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
const maxAllowedFractionDigits = 20;

type UnrestrictedFractionDigits = "UNRESTRICTED_FRACTION_DIGITS";

interface FormatCurrencyArgs {
  num: number;
  baseCurrency: string | null;
  maxFractionDigits?: UnrestrictedFractionDigits;
  minimumFractionDigits?: number;
  noCurrencySymbol?: boolean;
}

export function formatCurrency({
  num,
  baseCurrency,
  maxFractionDigits,
  minimumFractionDigits = 2,
  noCurrencySymbol,
}: FormatCurrencyArgs): string {
  if (baseCurrency === null) return formatNumberWithCommas(num);
  if (baseCurrency === "usdc") return `${formatNumberWithCommas(num)} USDC`;

  const value = Number(num);

  return value.toLocaleString("en-EN", {
    style: noCurrencySymbol ? "decimal" : "currency",
    currency: baseCurrency,
    minimumFractionDigits,
    maximumFractionDigits: maxFractionDigits
      ? maxAllowedFractionDigits
      : undefined,
  });
}
